<script setup>
import { ref, defineProps, onBeforeUnmount, computed } from "vue";
import BaseModal from "@/components/ui/baseComponents/modal.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const store = useStore();
const route = useRoute();
const showModal = ref(false);
const props = defineProps({
  title: {
    type: String,
    default: "Adhoc Questions",
  },
  showAnswer: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    default: "adhoc",
  },
});
const questionText = ref("");
const answerText = ref("");
const questions = computed({
  get() {
    return store.getters[`InterviewProcess/get${props.name}Questions`];
  },
  set(value) {
    store.commit("InterviewProcess/setSepcialQuestions", {
      type: props.name,
      questions: value,
    });
  },
});
const addNewQuestion = () => {
  const question = {
    question: questionText.value,
    answer: answerText.value,
  };
  const allQuestions = [...questions?.value, question];
  questions.value = allQuestions;
  questionText.value = "";
  answerText.value = "";
  showModal.value = false;
};
onBeforeUnmount(async () => {
  const response = await store.dispatch(
    "InterviewProcess/postSpecialQuestions",
    {
      type: props.name,
      questions:questions.value,
      interview:route.params.interviewId
    }
  );
  if (response.success) {
    console.log("go ahead and save the answer to the database");
  }
});
</script>
<template>
  <div class="bg-gray-200 w-full rounded-xl p-3">
    <div class="flex justify-end">
      <button
        @click="showModal = true"
        type="button"
        class="
          text-white
          bg-blue-700
          flex
          items-center
          hover:bg-blue-800
          font-medium
          rounded-lg
          text-sm
          px-5
          py-2.5
          mr-2
          mb-2
          dark:bg-blue-600 dark:hover:bg-blue-700
          focus:outline-none
          dark:focus:ring-blue-800
        "
      >
        <plus-icon></plus-icon>
        {{ props.title }}
      </button>
    </div>
    <div class="grid grid-cols-3 gap-4">
      <div
        v-for="item in questions"
        :key="item.index"
        class="border border-3 border-gray-400 bg-white py-3 px-4 rounded-md break-words"
      >
        <div class="my-2 text-md"><span class="font-medium text-md">Question:</span> {{ item.question }}</div>
        <div class="my-2 text-sm" v-if="item.answer.length">
         <span class="font-medium text-md"> Ans:</span>{{ item.answer }}
        </div>
      </div>
    </div>

    <BaseModal v-model:show="showModal" @confirm="addNewQuestion">
      <template #title> Add A New Question </template>
      <template #content>
        <div class="my-1 sm:ml-2 md:ml-10">
          <div class="w-full pr-10">
            <label
              class="font-semibold text-grey flex pb-1"
              for="ad_hoc_question"
              >Question</label
            >
            <input
              v-model="questionText"
              id="ad_hoc_question"
              type="text"
              name="question"
              class="
                rounded-lg
                border border-darkGrey
                bg-lightGrey
                w-full
                h-10
                pl-2
              "
            />
          </div>
          <div class="w-full pr-10">
            <label class="font-semibold text-grey flex pb-1" for="ad_hoc_answer"
              >Answer</label
            >
            <textarea
              v-model="answerText"
              rows="5"
              id="ad_hoc_answer"
              name="answer"
              class="rounded-lg border border-darkGrey bg-lightGrey w-full pl-2"
            ></textarea>
          </div>
        </div>
      </template>
      <template #successButton> Add </template>
    </BaseModal>
  </div>
</template>
