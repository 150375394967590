<script setup>
// import SingleQuestion from "./singleQuestion.vue";
import { handleError } from "@/store/modules/errorServices";
import { ref, inject, computed } from "vue";
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import { useStore } from "vuex";
import {
  GETLOADING,
  SINGLE_APPRAISAL_GETTER,
} from "@/store/modules/MA/appraisals/constants.js";

// const question = {
//   id: 3,
//   policy: 3,
//   head: 1,
//   type: "descriptive",
//   question: "What type of work environment do you prefer",
//   deleted_at: null,
//   created_at: "2022-07-21T14:35:22.000000Z",
//   updated_at: "2022-07-21T14:35:22.000000Z",
//   options: [],
// };
// var openSlide = ref(false);
const toast = inject("toast");
const store = useStore();
const singleAppraisalDetails = computed(() => {
  var single = store.getters[`Appraisals/${SINGLE_APPRAISAL_GETTER}`];
  for (let i = 0; i < single.user.objectives.length; i++) {
    if (single.user.objectives[i].status === 0) {
      single.user.objectives[i].status = "Incomplete";
    } else {
      single.user.objectives[i].status = "Complete";
    }
  }
  return single;
});
const loading = computed(() => store.getters[`Appraisals/${GETLOADING}`]);

const locals = ["New Goals", "Prior Goals"];
const dropdownOptions = ref([
  {
    label: "Completed",
    value: 1,
  },
  {
    label: "Incomplete",
    value: 0,
  },
]);
var priorGoals = ref(singleAppraisalDetails.value?.user?.objectives);
const priorGoalsCheck = ref(JSON.parse(JSON.stringify(singleAppraisalDetails.value?.user?.objectives)));
var objectivesArray = ref([{ objective: null, due_date: null }]);
const addObjective = () => {
  objectivesArray.value.push({ objective: null, due_date: null });
};
const removeNewGoal = (index) => {
  objectivesArray.value.splice(index, 1);
};
const submitGoals = async () => {
  for(let i=0 ; i < objectivesArray.value?.length ; i++){
    if(objectivesArray.value[i]?.objective === null || 
      objectivesArray.value[i]?.due_date === null || 
      objectivesArray.value[i]?.objective === "" ||
      objectivesArray.value[i]?.due_date === ""){
      toast.error("Please fill the goals.");
      return
    }
  }
  const objectives = {
    appraisal: singleAppraisalDetails.value.id,
    objectives: objectivesArray.value,
  };
  await store.dispatch("Appraisals/submitAppraisalGoals", objectives);
  toast.success("Submitted Successfully!");
  objectivesArray.value = [];
  objectivesArray.value = [{ objective: null, due_date: null }];
};
const deleteObjective = async (id, index) => {
  try {
    await store.dispatch("Appraisals/deleteAppraisalGoal", {
      user_objective: id,
    });
    toast.success("Objective Deleted Successfully!");
    priorGoals.value.splice(index, 1);
  } catch (e) {
    handleError(e.response.data);
  }
};
const editObjective = async (item, index) => {
  if(item?.status?.label ? item.status?.label === priorGoalsCheck.value[index].status : item?.status === priorGoalsCheck.value[index].status && item?.due_date === priorGoalsCheck.value[index].due_date){
    toast.error("Please Edit first.");
    return;
  }
  var obj = {
    user_objective: item.id,
    status: item.status,
    due_date: item.due_date,
  };
  if (item?.status?.label === "Incomplete" || item?.status?.label === "Incomplete") {
    obj.status = 0;
  } else {
    obj.status = 1;
  }
  try {
    await store.dispatch("Appraisals/editAppraisalGoal", obj);
    toast.success("Objective Edited Successfully!");
  } catch (e) {
    handleError(e.response.data);
  }
};
// const switchFollowUp = () => {
//   openSlide = !openSlide.value;
//   console.log("Open slide", openSlide);
// };
</script>
<template>
  <div class="flex-row w-full gap-5 p-2">
    <div>
      <!-- <div class="bg-gray-200 rounded-xl p-3">
            <SingleQuestion
              :data="question"
              :index="1"
              :title="'Manager Pre-Appraisal'"
            />
          </div> -->
      <div class="bg-gray-200 rounded-md py-3 px-3 mt-5">
        <div class="rounded-md bg-white p-4">
          Goals

          <template v-for="iter in locals" :key="iter.index">
            <el-divider> {{ iter }} </el-divider>
            <div class="flex flex-col">
              <div class="flex justify-between gap-5">
                <div class="w-2/4">Goals / Objective</div>
                <div class="w-2/4">Due Date</div>
                <div class="w-2/4"><span v-if="iter === 'Prior Goals'">Status</span></div>
                <div class="w-1/8">Action</div>
              </div>
              <div v-if="iter === 'New Goals'">
                <div
                  class="flex justify-between gap-5 mt-3"
                  v-for="(item, index) in objectivesArray"
                  :key="item"
                >
                  <div class="w-2/4 h-full">
                    <input
                      required
                      type="text"
                      v-model="item.objective"
                      name="objective"
                      class="
                        rounded-lg
                        border
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-6
                        pl-2
                      "
                    />
                  </div>
                  <div class="w-2/4 h-full">
                    <input
                      required
                      type="date"
                      v-model="item.due_date"
                      name="objective"
                      class="
                        rounded-lg
                        border
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-6
                        pl-2
                      "
                    />
                  </div>
                  <div class="w-2/4 h-full">&nbsp;
                  </div>
                  <div @click="removeNewGoal(index)" class="flex w-1/8 pt-2">
                    <span class="material-icons text-red ml-1 cursor-pointer">
                      remove_circle_outline
                    </span>
                  </div>
                </div>
                <div class="flex justify-end bg-white pb-2">
                  <p
                    class="
                      w-20
                      mx-1
                      my-1
                      md:mr-5
                      text-center text-white
                      rounded-full
                      cursor-pointer
                    "
                    @click="addObjective"
                  >
                    <span
                      class="
                        material-icons
                        text-3xl text-teal
                        ml-1
                        cursor-pointer
                      "
                    >
                      add_circle
                    </span>
                  </p>
                </div>

                <div class="flex justify-end bg-white pb-2">
                  <BaseButton @click="submitGoals" :animateButton="loading"
                    >{{ loading ? '' : 'Submit'}}</BaseButton
                  >
                </div>
              </div>
              <div v-if="iter === 'Prior Goals'">
                <div
                  class="flex justify-between gap-5 mt-3"
                  v-for="(item, index) in priorGoals"
                  :key="item"
                >
                  <div class="w-2/4 h-full">
                    <input
                      disabled
                      required
                      type="text"
                      v-model="item.objective"
                      name="objective"
                      class="
                        rounded-lg
                        border
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-6
                        pl-2
                      "
                    />
                  </div>
                  <div class="w-2/4 h-full">
                    <input
                      required
                      type="date"
                      v-model="item.due_date"
                      name="objective"
                      class="
                        rounded-lg
                        border
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-6
                        pl-2
                      "
                    />
                  </div>
                  <div class="w-2/4 h-full">
                    <v-select
                      v-model="item.status"
                      item-text="label"
                      class="s rounded-lg w-full sm:w-11/12 h-6"
                      placeholder="Please Select"
                      :options="dropdownOptions"
                    ></v-select
                    >&nbsp;
                  </div>
                  <div class="flex flex-row w-1/8 pt-2">
                    <span
                      @click="editObjective(item, index)"
                      class="material-icons text-teal ml-1 cursor-pointer"
                    >
                      edit
                    </span>
                    <span
                      @click="deleteObjective(item.id, index)"
                      class="material-icons text-red ml-1 cursor-pointer"
                    >
                      remove_circle_outline
                    </span>
                  </div>
                </div>
                <div v-if="priorGoals.length < 1" class="content-center">
                  No Prior Goals found
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- <div class="bg-gray-200 w-1/2 rounded-xl p-3">
          <SingleQuestion
            :data="question"
            :index="1"
            :title="'Manager Appraisal'"
          />
          <SingleQuestion :data="question" :index="3" :title="' '" />
        </div> -->
      </div>
    </div>
    <!-- <base-modal
      v-model:show="openSlide"
      :showCancel="false"
      @confirm="switchFollowUp()"
    >
      <template #title>Follow-Up Items</template>
      <template #content> Testing for follow up items. </template>
      <template #successButton>Close</template>
    </base-modal> -->
    <!-- <div class="flex justify-center p-2">
      <button
        type="button"
        class="
          text-sm
          bg-orange
          hover:bg-orange
          text-white
          py-1
          px-2
          rounded
          focus:outline-none focus:shadow-outline
        "
        @click="switchFollowUp()"
      >
        Follow-Up Items
      </button>
    </div> -->
  </div>
</template>