<script setup>
import { ref, defineEmits } from "vue";
import SignaturePad from "vue3-signature-pad";
const emits = defineEmits(["saveSignature"]);
const customStyle = { border: "black 3px solid", margin: "auto" },
  saveType = "image/svg+xml",
  saveOutput = "file";
const signatureDataURL = ref(null);
const signatureFile = ref(null);
const signaturePad = ref(null);

const getSignaturePad = () => {
  if (!signaturePad.value) {
    throw new Error("No signature pad ref could be found");
  }
  return signaturePad.value;
};

const clearSignature = () => {
  getSignaturePad().clearSignature();
};
clearSignature;

const saveSignature = () => {
  const signature = getSignaturePad().saveSignature();
  emits("saveSignature", signature);
  return signature;
};

const onInput = (value) => {
  if (!value) {
    signatureDataURL.value = null;
    signatureFile.value = null;
  } else if (value instanceof File) {
    signatureDataURL.value = null;
    signatureFile.value = value;
  } else {
    signatureDataURL.value = value;
    signatureFile.value = null;
  }
};
</script>

<template>
  <div>
    <SignaturePad
      :modelValue="signatureFile"
      @input="onInput"
      :width="500"
      :height="300"
      :customStyle="customStyle"
      :saveType="saveType"
      :saveOutput="saveOutput"
      ref="signaturePad"
    >
    </SignaturePad>
    <div class="saveButton text-right" @click="saveSignature">
      <slot name="saveButton"></slot>
    </div>
  </div>
</template>
