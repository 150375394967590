<script setup>
import { computed, inject, ref, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import RotaCard from "@/components/ui/baseComponents/rotaCardWithData.vue";
import ScheduleInterview from "@/components/layout/RE/New_Interview/Right_Side/ScheduleInterview.vue";
import {
  SINGLE_APPRAISAL_GETTER,
  RESCHEDULEAPPRAISALACTION,
} from "@/store/modules/MA/appraisals/constants.js";

const emits = defineEmits(["next_section"]);
const store = useStore();
const router = useRouter();
const toast = inject("toast");
const appraisalData = computed(() => {
  return store.getters[`Appraisals/${SINGLE_APPRAISAL_GETTER}`];
});

const enable_form = ref(false);
const reason = ref("");

const submitForm = async (data) => {
  try {
    const newObj = {
      practice: appraisalData.value.practice.id,
      user: appraisalData.value.user.id,
      type: appraisalData.value.type,
      department: appraisalData.value.user.department_id,
      appraisal_reference: appraisalData.value.id,
      ...data,
      reason,
    };
    const oldObj = {
      appraisal: appraisalData.value.id,
      is_rescheduled: 1,
      reschedule_reason: reason.value,
    };
    const { response1, response2 } = await store.dispatch(
      `Appraisals/${RESCHEDULEAPPRAISALACTION}`,
      { newObj, oldObj }
    );
    toast.show("Appraisals is successfully rescheuled");

    if (response1.status === 200 && response2.status === 200) {
      router.push({
        name: "ma-appraisals",
      });
    }
  } catch (error) {
    toast.show(error);
  }
};
</script>
<template>
  <div class="w-full h-full">
    <div class="px-5">
      <div class="flex gap-5">
        <div class="w-1/4 bg-gray-300 p-5 rounded-md">
          <p class="text-sm mb-5">
            Does this Employee need a follow-up meeting? If Yes, please provide
            reasoning.
          </p>

          <base-button @click="enable_form = true">yes</base-button>
          <base-button
            mode="cancel"
            @click="
              () => {
                emits('next_section');
              }
            "
            >No</base-button
          >
        </div>
        <div class="w-3/4 bg-gray-300 p-5 rounded-md">
          <div class="flex flex-col h-full">
            <label for="details">Reason</label>
            <textarea
              :disabled="!enable_form"
              v-model="reason"
              id="details"
              name="details"
              class="w-full h-full p-3"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="flex gap-5 mt-5">
        <div class="flex flex-col justify-between w-3/4">
          <div class="flex rounded-md flex-col justify-start">
            <RotaCard
              :showSelect="false"
              :bgColour="'bg-gray-200 px-3 mt-5 p-5 px-5'"
              :showTitle="true"
              :data="appraisalData?.user.work_patterns[0]?.work_timings || []"
              :title="'Rota Information'"
              :titleClass="'font-medium'"
            ></RotaCard>
          </div>
          <div class="mt-5 bg-gray-200 rounded-md p-5">
            <p class="text-md font-medium">Policies Information</p>
            <div
              v-for="policy in appraisalData.appraisal_policies"
              :key="policy.id"
            >
              <p>
                Policy Name:
                <span class="font-medium">{{ policy.name }}</span>
              </p>
              <p>
                Policy Questions:
                <span class="font-medium">{{ policy.questions.length }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="w-1/4 bg-gray-300 p-5 rounded-md">
          <ScheduleInterview
            @submit-form="submitForm"
            :title="'Rescheule Appraisal'"
          />
        </div>
      </div>
    </div>
  </div>
</template>