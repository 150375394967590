<script setup>
import { defineProps, ref, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const saveAnswerAction = inject("saveAnswer");
const saveAnswerModule = inject("vuexModule");
const typeOfAction = inject("type");

const store = useStore();
const route = useRoute();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
});

const answer = ref(props.data?.answer || []);
var saveResponse = "";

const saveAnswer = () => {
  saveResponse = store.dispatch(`${saveAnswerModule}/${saveAnswerAction}`, {
    [typeOfAction]: Number(
      route.params.interviewId ? route.params.interviewId : route.params.id
    ),
    question: props.data.id,
    ...(props.data.type === "descriptive" && { answer: answer.value }),
    ...(props.data.type === "single-choice" && { option: answer.value }),
    ...(props.data.type === "multi-choice" && { options: answer.value }),
  });
  console.log("save response: ", saveResponse);
};
</script>
<template>
  <div class="flex flex-col">
    <p class="text-2xl font-medium">
      {{ props.title ? props.title : `Question Number ${props.index}` }}
    </p>
    <div class="flex flex-grow flex-col my-3">
      <div
        class="bg-white rounded-md w-full p-4 overflow-auto border border-gray-400"
      >
        <div class="font-medium mb-5">
          {{ props.data.question }}
        </div>
        <template v-if="props.data.type === 'descriptive'">
          <span class="border border-gray-400 px-4 border-b-0 rounded"
            >Answer</span
          >
          <textarea
            v-model="answer"
            name="answer"
            id="answer"
            rows="5"
            class="border p-3 border-gray-400 w-full rounded-md"
            maxlength="250"
          ></textarea>
        </template>
        <template v-if="props.data.type === 'multi-choice'">
          <div>
            <div
              class="form-check"
              v-for="item in props.data.options"
              :key="item.index"
            >
              <input
                v-model="answer"
                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                :value="item.id"
                :id="item.id"
              />
              <label
                class="form-check-label inline-block text-gray-800"
                :for="item.id"
              >
                {{ item.option }}
              </label>
            </div>
          </div>
        </template>
        <template v-if="props.data.type === 'single-choice'">
          <div>
            <div
              class="form-check"
              v-for="item in props.data.options"
              :key="item.index"
            >
              <input
                v-model="answer"
                class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                :value="item.id"
                :id="item.id"
              />
              <label
                class="form-check-label inline-block text-gray-800"
                :for="item.id"
              >
                {{ item.option }}
              </label>
            </div>
          </div>
        </template>
      </div>
      <div class="flex justify-end mt-auto">
        <base-button
          @click="saveAnswer"
          class="bg-gray-200 rounded-md px-3 py-2 my-4 focus:bg-yellow-300"
          >Save</base-button
        >
      </div>
    </div>
  </div>
</template>
