<script setup>
import { ref, defineEmits, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";
import { SINGLE_APPRAISAL_GETTER } from "@/store/modules/MA/appraisals/constants.js";

// import SingleEditableDetail from "@/components/layout/generalUser/singleEditableDetail.vue";

const store = useStore();
const emits = defineEmits(["concludeAppraisal"]);
const singleAppraisalDetails = computed(() => {
  return store.getters[`Appraisals/${SINGLE_APPRAISAL_GETTER}`];
});

const applicationStatus = (approv) => {
  console.log("approv:", approv);
  tableState.value = approv;
  const obj = {
    appraisal: singleAppraisalDetails.value.id,
    is_approved: tableState.value,
    remarks: remarks.value,
  };
  console.log("on before mount : ", obj);
  emits("concludeAppraisal", obj);
};

const remarks = ref("");
var tableState = ref(3);
const cards = {
  title: "Position Information",
  fields: [
    {
      title: "Job Title:",
      value: singleAppraisalDetails.value?.user?.profile?.primary_role,
    },
    {
      title: "Contract Type:",
      value: singleAppraisalDetails.value?.position_summary?.contract_type,
    },
    {
      title: "Department:",
      value: singleAppraisalDetails.value?.user?.department.name,
    },
    {
      title: "Reports to:",
      value: `${singleAppraisalDetails.value?.user?.department?.department_head?.profile?.first_name}
        ${singleAppraisalDetails.value?.user?.department?.department_head?.profile?.last_name}`,
    },
    {
      title: "Probation end date:",
      value: singleAppraisalDetails.value?.position_summary?.probation_end_date,
    },
    {
      title: "Notice Period:",
      value: singleAppraisalDetails.value?.position_summary?.notice_period,
    },
  ],
};

onBeforeUnmount(() => {
  console.log("onBeforeUnm", singleAppraisalDetails.value);
});
</script>
<template>
  <div class="container">
    <div class="flex gap-5">
      <div class="w-2/3">
        <div class="flex flex-col gap-y-5">
          <div class="p-5 bg-gray-100 rounded-md">
            <p class="font-medium mb-5">Position Information</p>
            <div class="flex flex-col">
              <div class="flex" v-for="item in cards.fields" :key="item.index">
                <p class="text-md font-medium mb-1">
                  {{ item.title }} {{ item.value }}
                </p>
                <div class="flex flex-grow">
                  <div class="flex flex-col w-full">
                    <div
                      class="flex flex-grow justify-between items-center"
                      v-for="info in item.fields"
                      :key="info.index"
                    >
                      {{ info }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 bg-gray-100 rounded-md">
            <div class="">
              <span class="border border-gray-400 px-4 border-b-0 rounded"
                >Remarks:</span
              >
              <textarea
                v-model="remarks"
                name="notes"
                id="notes"
                rows="5"
                class="border p-3 border-gray-400 w-full rounded-md"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-y-5 w-1/3">
        <div class="p-5 bg-gray-100 rounded-md">
          <p class="font-medium">Overview</p>
          <div
            class="flex flex-col"
            v-for="item in singleAppraisalDetails.appraisal_policies[0]
              .questions"
            :key="item.index"
          >
            <p class="text-md mb-1 font-medium">
              Question: {{ item.question }}
            </p>
            <p class="text-md mb-1">
              Answer: {{ item.answer ? item.answer : "no answer" }}
            </p>
          </div>
        </div>
        <div
          class="
            p-5
            bg-gray-100
            rounded-md
            flex flex-row-reverse
            text-sm
            gap-x-1
          "
        >
          <span
            @click="applicationStatus(0)"
            class="
              tab
              cursor-pointer
              inline-block
              py-1
              px-7
              rounded-md
              border-2 border-teal
            "
            :class="{ buttonActive02: tableState === 0 }"
          >
            Decline
          </span>
          <span
            @click="applicationStatus(1)"
            class="
              tab
              cursor-pointer
              inline-block
              py-1
              px-7
              rounded-md
              border-2 border-teal
            "
            :class="{ buttonActive01: tableState === 1 }"
          >
            Approve
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.buttonActive01 {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
.buttonActive02 {
  border-color: darkred;
  background-color: darkred;
  color: white;
  padding-top: 2px;
}
</style>