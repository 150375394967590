<script setup>
import { defineProps, defineEmits, toRefs, ref, computed } from "vue";
import SingleQuestion from "./singleQuestion.vue";
import IntervieweeProfile from "./IntervieweeProfile.vue";
import AdHocQuestions from "./adHocQuestions.vue";
import SalaryAndNotice from "./SalaryAndNotice.vue";
import FinalStep from "./FinalStep.vue";
import AppraisalFinalStep from "./AppraisalFinalStep.vue";
import ReSchedule from "./ReSchedule.vue";
import AppraisalComponent from "./AppraisalComponent.vue";
import { useStore } from "vuex";

const store = useStore();
const data = computed(() => {
  return store.getters["InterviewProcess/getActiveInterview"];
});

const miscInfo = data.value?.first_interview_data?.misc_info;
const props = defineProps({
  currentStep: {
    type: Object,
    required: true,
  },
  activeStep: {
    type: Number,
    default: 0,
  },
  interviewSteps: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits([
  "gotoNextStep",
  "gotoPreviousStep",
  "completeInterview",
  "applicantStatusForConduct",
]);
const { currentStep } = toRefs(props);
const { activeStep } = toRefs(props);
const { interviewSteps } = toRefs(props);
const nextStep = computed(() => {
  let index = interviewSteps.value.findIndex(
    (a) => a.title === currentStep.title
  );
  return interviewSteps.value[++index];
});
console.log(nextStep.value);
const internalStep = ref(0);
const CardTypes = {
  profileCard: IntervieweeProfile,
  descriptive: SingleQuestion,
  "single-choice": SingleQuestion,
  "multi-choice": SingleQuestion,
  ReSchedule,
  AdHocQuestions,
  SalaryAndNotice,
  FinalStep,
  AppraisalFinalStep,
  AppraisalComponent,
};
const gotoNextStep = () => {
  emits(
    interviewSteps.value.length - 1 === activeStep.value
      ? "completeInterview"
      : "gotoNextStep"
  );
  internalStep.value = 0;
};
const emitApplicationStatus = (value) => {
  console.log("applicant status at interview runner: ", value);
  emits("applicantStatusForConduct", value);
};
const concludeAppraisalFunc = (value) => {
  console.log("interview runner emit:", value);
  emits("concludeAppraisal", value);
};
</script>
<template>
  <div class="mx-4 sm:px-4 md:px-0 md:mt-10 h-full md:h-auto flex-grow">
    <div class="flex-grow flex-col h-full flex">
      <div class="flex-grow flex-col flex">
        <div class="flex-grow h-full flex gap-6">
          <template v-if="currentStep.questions">
            <div class="bg-gray-200 w-full rounded-xl p-3">
              <component
                :key="
                  activeStep +
                  ' ' +
                  currentStep.questions?.[internalStep + 1]?.id
                "
                :index="activeStep > 0 ? internalStep + 1 : internalStep"
                :data="currentStep.questions?.[internalStep]"
                :is="CardTypes[currentStep.questions?.[internalStep]?.type]"
              ></component>
            </div>
            <div class="bg-gray-200 w-auto break-all rounded-xl p-3">
              <component
                :key="
                  activeStep +
                  ' ' +
                  currentStep.questions?.[internalStep + 1]?.id
                "
                :index="
                  activeStep > 0
                    ? currentStep.questions.length >= internalStep + 1
                      ? internalStep + 2
                      : 0
                    : internalStep + 1
                "
                :data="
                  currentStep.questions.length >= internalStep + 1
                    ? currentStep.questions?.[internalStep + 1]
                    : nextStep.questions?.[0]
                "
                :is="CardTypes[currentStep.questions?.[internalStep + 1]?.type]"
              ></component>
            </div>
          </template>
          <template v-else>
            <component
              :name="currentStep.name"
              :showAnswer="currentStep.title !== 'Candidate Questions'"
              :key="CardTypes[currentStep.type] + activeStep"
              :title="currentStep.title"
              :salaryNotice="miscInfo"
              :is="CardTypes[currentStep.type]"
              @next_section="gotoNextStep()"
              @applicantStatus="emitApplicationStatus"
              @concludeAppraisal="concludeAppraisalFunc"
            ></component>
          </template>
        </div>
      </div>
      <div class="flex justify-start w-full">
        <div class="flex-grow ml-5 flex justify-between">
          <div class="">
            <button
              v-if="internalStep > 0 || activeStep > 0"
              @click="
                internalStep > 0 ? internalStep-- : emits('gotoPreviousStep')
              "
              class="bg-gray-200 rounded-md px-3 py-2 my-4"
            >
              {{
                internalStep > 0 ? " Previous Question" : " Previous Section"
              }}
            </button>
          </div>
          <button
            @click="
              internalStep + 1 < currentStep.questions?.length - 1
                ? internalStep++
                : gotoNextStep()
            "
            class="bg-gray-200 rounded-md px-3 py-2 my-4"
          >
            {{
              interviewSteps.length - 1 === activeStep
                ? "Complete"
                : currentStep.questions?.length - 1 > internalStep + 1
                ? "Next Question"
                : "Next Section"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
