<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import VerticalTimeline from "@/components/ui/baseComponents/verticleTimeline.vue";
const store = useStore();
const MainInfo = ref([]);
var educationInformation = {};
var experienceInformation = {};
var updateInfo = 0;
const currentInterview = computed(() => {
  const activeINtervew = store.getters["InterviewProcess/getActiveInterview"];
  // const eduArr = [
  //   {
  //     title: "Position Applied For",
  //     value: "institution",
  //   },
  //   {
  //     title: "Role",
  //     value: "subject",
  //   },
  //   {
  //     title: "Phone",
  //     value: "start_date",
  //   },
  //   {
  //     title: "Email",
  //     value: "completion_date",
  //   },
  //   {
  //     title: "NHS Number",
  //     value: "degree",
  //   },
  //   {
  //     title: "NHS Smart Card",
  //     value: "grade",
  //   },
  // ];
  const arr = [
    {
      title: "Position Applied For",
      value: "primary_role",
    },
    {
      title: "Role",
      value: "primary_role",
    },
    {
      title: "Phone",
      value: "work_phone",
    },
    {
      title: "Email",
      value: "professional_email",
    },
    {
      title: "NHS Number",
      value: "nhs_number",
    },
    {
      title: "NHS Smart Card",
      value: "nhs_card",
    },
  ];
  if (activeINtervew) {
    arr.map((item) => {
      MainInfo.value = [
        ...MainInfo.value,
        {
          title: item.title,
          value: activeINtervew?.user.profile[item.value],
        },
      ];
    });
    // educationInformation.value = [
    //     ...educationInformation.value,
    //     {
    //       value: activeINtervew?.user.education,
    //     },
    // console.log(educationInformation, "education information:")
    // ];
  }
  return activeINtervew;
});
function getInfo() {
  for (let i = 0; i < currentInterview.value?.user.education.length; i++) {
    educationInformation[i] = currentInterview.value?.user.education[i];
    console.log(educationInformation, "education info");
  }
  for (
    let i = 0;
    i < currentInterview.value?.user.employment_histories.length;
    i++
  ) {
    experienceInformation[i] =
      currentInterview.value?.user.employment_histories[i];
    console.log(experienceInformation, "experience info");
  }
  updateInfo++;
}

onMounted(() => {
  getInfo();
});
</script>
  <template>
  <div class="flex flex-col flex-grow">
    <div class="bg-white rounded-md py-5">
      <div class="flex">
        <span class="material-icons text-8xl cursor-pointer mx-4">
          account_circle
        </span>
        <div class="flex-grow px-3">
          <div class="font-medium text-2xl mb-3">
            {{ interview?.user.profile.first_name }}
            {{ interview?.user.profile.last_name }}
          </div>
          <div class="font-medium" v-for="item in MainInfo" :key="item.index">
            <span class="text-gray-400 font-normal">{{ item.title }}:</span>
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-grow">
      <div class="flex gap-4 justify-between my-3">
        <div
          :key="updateInfo"
          class="bg-white rounded-md w-full p-4 overflow-auto"
        >
          <div class="font-medium text-2xl mb-3">Educational Information</div>
          <div
            class="font-medium"
            v-for="item in educationInformation"
            :key="item.index"
          >
            <VerticalTimeline>
              <template #title> {{ item.degree }} </template>
              <template #details>
                <!-- <div class="" v-for="edu in Object.keys(item)" :key="edu.index">
                  <span class="text-gray-400 font-normal">{{ edu }}:</span>
                  {{ item[edu] }}
                </div> -->
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Institution:</span>
                  {{ item.institution }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Start Date:</span>
                  {{ item.start_date }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">End Date:</span>
                  {{ item.completion_date }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Subject:</span>
                  {{ item.subject }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Grade:</span>
                  {{ item.grade }}
                </div>
              </template>
            </VerticalTimeline>
          </div>
        </div>
        <div
          :key="updateInfo"
          class="bg-white rounded-md w-full p-4 overflow-auto"
        >
          <div class="font-medium text-2xl mb-3">Experience Information</div>

          <div
            class="font-medium"
            v-for="item in experienceInformation"
            :key="item.index"
          >
            <VerticalTimeline>
              <template #title> {{ item.job_title }} </template>
              <template #details>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Employer Name:</span>
                  {{ item.employer_name }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Salary:</span>
                  £{{ item.salary }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">Start Date:</span>
                  {{ item.job_start_date }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal">End Date:</span>
                  {{ item.job_end_date }}
                </div>
                <div class="font-medium">
                  <span class="text-gray-400 font-normal"
                    >Reason for Leaving:</span
                  >
                  {{ item.reason_for_leaving }}
                </div>
              </template>
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  