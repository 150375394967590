<template>
  <div class="relative max-w-2xl">
    <div
      class="absolute top-0 h-full border-r-2 border-gray-500"
      style="left: 5px"
    ></div>
    <ul class="space-y-1">
      <li>
        <div class="flex items-center">
          <span class="w-3 h-3 bg-gray-500 rounded-full"></span>
          <h5 class="ml-4 font-bold text-gray-600">
            <slot name="title"></slot>
          </h5>
        </div>
        <div class="ml-7">
          <slot name="details"></slot>
        </div>
      </li>
    </ul>
  </div>
</template>