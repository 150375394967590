<script setup>
import { ref, computed, reactive, inject, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const emits = defineEmits(["applicantStatus"]);
const emitter = inject("emitter");
const store = useStore();
const route = useRoute();
var tableState = ref(3);

const currentInterview = computed(() => {
  return store.getters["InterviewProcess/getActiveInterview"];
});
emitter.on("onSaveSignature", () => {
  const result = {};
  scoring.map((item) => {
    result[item.name] = item.value;
  });
  result["remarks"] = remarks.value;
  result.total = total.value;
  result.interview = route.params.interviewId;
  emits("applicantStatus", tableState.value);
  store.dispatch("InterviewProcess/postScoreInfo", result);
});
const scoring = reactive([
  { title: "Cultural Fit", name: "cultural_fit", value: 0 },
  { title: "Career Motivation", name: "career_motivation", value: 0 },
  {
    title: "Social Skills",
    name: "social_skills",
    value: 0,
  },
  {
    title: "Team Work",
    name: "team_work",
    value: 0,
  },
  {
    title: "Technical Skills",
    name: "technical_skills",
    value: 0,
  },
  {
    title: "Leadership Capability",
    name: "leadership_capability",
    value: 0,
  },
  {
    title: "Critical Thinking & Problem Solving",
    name: "critical_thinking_problem_solving",
    value: 0,
  },
  {
    title: "Self-Awareness",
    name: "self_awareness",
    value: 0,
  },
]);
const total = computed(() => {
  console.log(
    "sum of total in f step: ",
    scoring.reduce((sum, { value }) => sum + value, 0)
  );
  return scoring.reduce((sum, { value }) => sum + value, 0);
});
const applicationStatus = (approv) => {
  console.log("approv:", approv);
  tableState.value = approv;
};
const remarks = ref("");
</script>
<template>
  <div class="flex-grow flex gap-4">
    <div class="flex flex-col flex-grow w-full">
      <div class="bg-gray-200 rounded-xl p-3">
        <p class="text-2xl font-medium">Scoring Rubric</p>
        <div
          class="flex items-center my-3 justify-between"
          v-for="item in scoring"
          :key="item.name"
        >
        
          <p>{{ item.title }}:</p>
          <input
            max="10"
            required
            type="number"
            v-model="item.value"
            class="border border-gray-400 rounded-md my-1"
          />
        </div>

        <div class="flex items-center my-3 justify-end mt-10">
          <p class="mr-5 font-medium">total:</p>
          <input
            disabled
            type="number"
            v-model="total"
            class="border border-gray-400 rounded-md my-1"
          />
        </div>
      </div>
    </div>
    <div class="bg-gray-200 flex flex-col flex-grow p-3 rounded-xl w-full">
      <p class="text-2xl font-medium">Recuiter's Remarks</p>
      <div class="my-4">
        <div class="">
          <span class="border border-gray-400 px-4 border-b-0 rounded"
            >Remarks:</span
          >
          <textarea
            v-model="remarks"
            name="notes"
            id="notes"
            rows="5"
            class="border p-3 border-gray-400 w-full rounded-md"
          ></textarea>
        </div>
      </div>
      <div>
        <p class="text-2xl font-medium my-4">Applicant's Status:</p>
        <div class="flex text-sm gap-x-1 my-4">
          <span
            @click="applicationStatus(1)"
            class="
              tab
              cursor-pointer
              inline-block
              py-1
              px-7
              rounded-md
              border-2 border-teal
            "
            :class="{ buttonActive01: tableState === 1 }"
          >
            Approve
          </span>
          <span
            @click="applicationStatus(0)"
            class="
              tab
              cursor-pointer
              inline-block
              py-1
              px-7
              rounded-md
              border-2 border-teal
            "
            :class="{ buttonActive02: tableState === 0 }"
          >
            Decline
          </span>
          <span
            v-if="currentInterview.application_status === 'first-interview'"
            @click="applicationStatus(2)"
            class="
              tab
              cursor-pointer
              inline-block
              py-1
              px-7
              rounded-md
              border-2 border-teal
            "
            :class="{ buttonActive03: tableState === 2 }"
          >
            Refer for 2nd interview
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.buttonActive01 {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
.buttonActive02 {
  border-color: red;
  background-color: red;
  color: white;
  padding-top: 2px;
}
.buttonActive03 {
  border-color: yellow;
  background-color: yellow;
  color: black;
  padding-top: 2px;
}
</style>
